import type {NitroFetchOptions} from "nitropack";

export function makeApiRequest<Response>(url: string, options: NitroFetchOptions<typeof url> = {
    method: "GET"
}) {
    const {$paymentApiV1} = useNuxtApp();

    return $paymentApiV1<Response>(url, options);
}

export function getServerResource<T>(url: string, resource: string | number, options = {}) {
    return makeApiRequest<T>(`${url}/${resource}`, options);
}

export function getServerResources<T>(url: string, params?: object) {
    return makeApiRequest<T[]>(url, {
        params: params
    });
}

export function createServerResource(url, resource) {
    return makeApiRequest(url, {
        method: "POST",
        body: JSON.stringify(resource)
    });
}

export function updateServerResource(url, resource) {
    return makeApiRequest(url, {
        method: "PUT",
        body: resource
    });
}

export function removeServerResource(url: string) {
    return makeApiRequest(url, {
        method: "DELETE"
    });
}